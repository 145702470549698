import * as React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

const PreviewPage = ({ isPreview }) => {
  return (
    <div className='text-center font-black leading-tight md:text-6xl text-5xl z-10 relative flex flex-col justify-center h-screen'>
      {isPreview === false ? "There's no preview here" : 'loading preview'}
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage)
